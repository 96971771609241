import Axios from './http';

export default class Work {
  /**
   * @description 查询邀請函列表
   * @return {HttpResponse} result
   */

  static async category(params) {
    return Axios('option/categories', {
      method: 'post',
      responseType: 'json',
    }).then((res) => {
      return res;
    });
  }
}
