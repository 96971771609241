import { reactive, ref } from 'vue';
import optionService from '@/services/Option';

import { useRoute } from 'vue-router';
export default ({ fields }) => {
  let loading = ref(false);
  const route = useRoute();

  const routeQuery = reactive({ ...query, ...route.query });
  delete routeQuery.page;
  delete routeQuery.limit;

  const query = reactive({ ...fields, ...routeQuery });

  const opts = reactive({});

  if (hasOwnProperty.call(query, 'category')) {
    optionService.category().then((res) => {
      if (res.code === 1) {
        opts.category = [...res.data];
      }
    });
  }

  return { query, opts };
};
