<template>
  <div class="flex justify-center my-8" v-if="total > limit">
    <El-Pagination
      :small="true"
      :page-size="limit"
      @current-change="update"
      v-model:currentPage="page"
      layout="total ,prev, pager, next "
      :total="total"
    />
  </div>
</template>

<script>
import { ElPagination } from 'element-plus';
import { inject, toRefs } from 'vue';
export default {
  components: { ElPagination },
  setup() {
    const { pagination } = inject('list');

    // const status = (item) => {
    //   const msgs = {
    //     FAIL: 'xXXXXXJJ',
    //     pending: 'xXXXXXJJ',
    //   };

    //   const getStatus = (item) => {
    //     return item.isOnline ? expired(item.isOnine) : booking(item.offOnine);
    //   };
    //   const expired = (backer) => {
    //     return backer.isOver ? over(backer.isOver) : before(backer.courseStart);
    //   };
    //   const over = (isOver) => {
    //     return isOver ? 'before' : 'prepare';
    //   };

    //   const booking = (courseStart) => {
    //     return courseStart ? 'before' : 'prepare';
    //   };
    //   const before = (courseStart) => {
    //     return courseStart ? 'before' : 'prepare';
    //   };

    //   return msgs[getStatus(item)];
    // };
    return {
      ...toRefs(pagination),
    };
  },
};
</script>

<style></style>
