<template>
  <el-form
    v-on:keypress.enter.prevent="submit"
    :inline="inline"
    :label-position="'top'"
    label-width="100px"
    :model="form"
  >
    <el-form-item label="關鍵字">
      <el-input v-model="form.keyword"></el-input>
    </el-form-item>

    <el-form-item label="年代" v-if="hasOwn('year')">
      <el-input v-model="form.year"></el-input>
    </el-form-item>
    <el-form-item label="類別" v-if="hasOwn('category')">
      <select
        class="form-select"
        v-model="form.category"
        :disabled="!opts.category"
      >
        <option v-if="!opts.category" value="null">目前沒有項目</option>
        <template v-else>
          <option
            v-for="opt in opts.category"
            :key="opt.cate"
            :value="opt.cate"
          >
            {{ opt.cate }}
          </option>
        </template>
      </select>
    </el-form-item>
    <el-form-item label="作者" v-if="hasOwn('creator')">
      <el-input v-model="form.creator"></el-input>
    </el-form-item>
    <el-form-item label="登錄號" v-if="hasOwn('serial')">
      <el-input v-model="form.serial"></el-input>
    </el-form-item>
    <el-form-item label="審核狀態" v-if="hasOwn('status')">
      <select class="form-select" v-model="form.status">
        <option value="">全部</option>

        <option value="New">新建</option>
        <option value="Review">審核中</option>
        <option value="Published">已發布</option>
        <option value="Invalid">審核失敗</option>
      </select>
    </el-form-item>

    <el-form-item
      class="date"
      label="展覽上架時間"
      v-if="hasOwn('start_ts') && hasOwn('end_ts')"
    >
      <El-Date-Picker
        v-model="form.start_ts"
        type="date"
        value-format="YYYY-MM-D"
        placeholder="開始日期"
      />
      <span class="mx-2 sep">至</span>
      <El-Date-Picker
        v-model="form.end_ts"
        value-format="YYYY-MM-D"
        type="date"
        placeholder="結束日期"
      />
    </el-form-item>

    <el-form-item class="submit" label=".">
      <El-Button
        class="xl py-[0.5rem]"
        :disabled="loading"
        :loading="loading"
        @click.prevent="submit"
      >
        搜尋
      </El-Button>
    </el-form-item>
  </el-form>
</template>

<script>
import { computed, inject, reactive, toRefs, watch } from 'vue';
import {
  ElButton,
  ElInput,
  ElForm,
  ElFormItem,
  ElDatePicker,
} from 'element-plus';

export default {
  components: {
    ElButton,
    ElInput,
    ElForm,
    ElFormItem,
    ElDatePicker,
  },
  props: {
    inline: {
      type: Boolean,
      default: true,
    },
  },

  setup(props) {
    const { query, loading, opts } = inject('filter');
    const { update } = inject('list');

    const hasOwn = (key) => {
      return hasOwnProperty.call(query, key);
    };

    const submit = () => {
      update({ ...query, page: 1 });
    };
    return { form: query, submit, hasOwn, loading, opts };
  },
};
</script>
